import React from 'react'
import image from '../../assets/images/careers/people-search.svg'
import WdcButton from '../elements/WdcButton'

const CareersSection = () => {
  return (
    <section className='container mt-4 py-4 md:py-8 lg:py-16 md:mt-8 lg:mt-12 xl:mt-8'>
      <div className='flex flex-col md:flex-row items-center w-11/12 lg:w-4/5 mx-auto'>
        <h2 className='m-0 md:w-2/3 sm:p-8 text-center md:text-left text-6xl'>
          Careers
        </h2>
        <img
          src={image}
          alt='A person handing a file across a table to another person'
          className='hidden lg:block md:w-72 mx-4 opacity-80'
        />
        <div className='flex flex-col w-90vw text-center'>
          <p>
            We're always on the lookout for special talent! Do you have what it
            takes?
          </p>
          <WdcButton
            buttonLink='/careers'
            buttonText='Join The Team'
            showIcon={true}
            className='mt-6 md:mt-2 w-max self-center'
          />
        </div>
      </div>
    </section>
  )
}

export default CareersSection
