import React from 'react'
import TeamMemberCard from '../elements/TeamMemberCard'
import { useTeamMembersList } from '../../custom-query-hooks/useTeamMembersList'

const MeetTheTeamSection = () => {
  const teamMembers = useTeamMembersList()

  return (
    <section className='container bg-gray-50 mt-12 text-center py-8 md:py-12'>
      <h2 className='text-center p-0 m-0'>Meet The Team</h2>
      <p className='mx-auto w-4/5 mt-4 p-0'>
        We Do Code do not outsource our projects to offshore companies.  We have
        an in-house team of software engineers with a wide range of skills,
        expertise and experience between them.  The team you see here is the team
        that will work with you to build your new solution, and help you deliver
        a return on investment that sees you hit your business goals.
      </p>
      <div className='mx-auto gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center mt-4 w-11/12'>
        {teamMembers.map(member => {
          return (
            <div className='w-full'>
              <TeamMemberCard
                name={member.name}
                jobTitle={member.jobTitle}
                bio={member.blurb.blurb}
                personalTitle={member.personallyChosenTitle}
                quote={member.quote}
                cartoonImg={member.cartoonImage}
                realImg={member.realImage}
              />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default MeetTheTeamSection
