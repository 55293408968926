import React from 'react'

import { BgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'
import WdcButton from '../elements/WdcButton'

const backgroundImageQuery = graphql`
  {
    file(relativeDirectory: { eq: "community-banner" }, name: { eq: "community-banner_resized" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
      name
    }
  }
`
const CommunityBanner = () => {
  const { file } = useStaticQuery(backgroundImageQuery)
  const image = getImage(file)
  const imageStack = [
    `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5))`,
    image,
  ]

  return (
    <section className='container relative mt-8 z-0 '>
      <BgImage
        image={imageStack}
        alt={file.name}
        className='w-full h-full rounded-md safari-image-fix'
      >
        <div className='flex flex-col items-center p-4 md:p-12 lg:p-16 xl:p-20'>
          <p className='text-center text-white text-xl md:text-3xl lg:text-4xl xl:text-5xl z-10'>We're involved in our local community!</p>
          <WdcButton
            buttonLink='/corporate-social-responsibility'
            buttonText='find out more!'
            className='justify-center mt-8 xl:mt-12 w-80vw max-w-min md:max-w-xs z-10'
            showIcon
          />
        </div>
      </BgImage>
    </section>
  )
}

export default CommunityBanner
