import React, { useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReactCardFlip from 'react-card-flip'

const TeamMemberCard = ({ cartoonImg, realImg, name, jobTitle, personalTitle, bio, quote }) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const realImgPath = getImage(realImg)
  const cartoonImgPath = getImage(cartoonImg)

  return (
    <ReactCardFlip isFlipped={isFlipped} flipSpeedFrontToBack={1} flipSpeedBackToFront={1}>
      <article className='border border-gray-200 w-full rounded-lg p-2 md:p-4 shadow-xl overflow-hidden h-44 md:h-card'>
        <button className='w-full h-full' onClick={() => setIsFlipped(!isFlipped)}>
          <div className='flex md:flex-col justify-between w-full h-full rounded-lg'>
            <div className='flex flex-col w-1/3 md:w-full items-center'>
              <div className='text-center'>
                <div
                  className={`flex h-16 w-16 md:w-48 md:h-48 rounded-full border-4 overflow-hidden safari-image-fix ${
                    !isFlipped ? 'border-primary' : 'border-transparent'
                  }`}
                >
                  <GatsbyImage
                    image={cartoonImgPath}
                    className={`rounded-full h-16 md:h-48 w-16 md:w-48 flex-grow-0 transition-all duration-700 ease-in-out ${
                      isFlipped ? 'opacity-0' : 'opacity-1'
                    }`}
                    alt={`Cartoon image of ${name}`}
                  />
                </div>
              </div>
              <div className='text-xs md:text-2xl font-semibold p-2'>{name}</div>
              <div className='text-xs md:text-lg md:p-2'>{personalTitle}</div>
            </div>
            <div className='flex-1 flex flex-col justify-between w-2/3 md:w-full'>
              <div className='flex-1'>
                <div
                  className={`grid h-full place-items-center text-base md:text-xl transition-all duration-700 ease-in-out ${
                    isFlipped ? 'opacity-0' : 'opacity-1'
                  }`}
                >
                  {'"' + quote + '"'}
                </div>
              </div>
              <div className={`text-xs transition-all duration-700 ease-in-out ${isFlipped ? 'opacity-0' : 'opacity-1'}`}>
                <span>Click</span> for my professional background!
              </div>
            </div>
          </div>
        </button>
      </article>
      <article className='border border-gray-200 w-full rounded-lg p-2 md:p-4 shadow-xl overflow-hidden h-44 md:h-card'>
        <button className='w-full h-full' onClick={() => setIsFlipped(!isFlipped)}>
          <div className='flex md:flex-col justify-between w-full h-full rounded-lg'>
            <div className='flex flex-col w-1/3 md:w-full items-center'>
              <div className='text-center'>
                <div
                  className={`flex h-16 w-16 md:w-48 md:h-48 rounded-full border-4 overflow-hidden safari-image-fix ${
                    isFlipped ? 'border-primary' : 'border-transparent'
                  }`}
                >
                  <GatsbyImage
                    image={realImgPath}
                    className={`rounded-full h-16 md:h-48 w-16 md:w-48 flex-grow-0 border-4 transition-all duration-700 ease-in-out ${
                      !isFlipped ? 'opacity-0' : 'opacity-1'
                    }`}
                    alt={`Real image of ${name}`}
                  />
                </div>
              </div>
              <div className='text-xs md:text-2xl font-semibold p-2'>{name}</div>
              <div className='text-xs md:text-lg md:p-2'>{jobTitle}</div>
            </div>
            <div
              className={`flex-1 flex flex-col justify-between w-2/3 md:w-full overflow-y-auto text-xs p-2 transition-all duration-700 ease-in-out ${
                !isFlipped ? 'opacity-0' : 'opacity-1'
              }`}
            >
              {bio}
            </div>
          </div>
        </button>
      </article>
    </ReactCardFlip>
  )
}

export default TeamMemberCard
