import React from 'react'

const VisionSection = () => {
  return (
    <section className='container'>
      <div className='w-4/5 mx-auto mt-12 md:mt-18 lg:mt-32'>
        <h2 className='text-center lg:text-5xl xl:text-6xl m-0'>Our Vision</h2>
        <p className='leading-loose text-center sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl m-0 mt-4 md:mt-8 lg:mt-16'>
          Make unaffordable technology affordable for all.
        </p>
      </div>
      <div className='w-4/5 mx-auto mt-12 md:mt-18 lg:mt-32'>
        <h2 className='text-center lg:text-5xl xl:text-6xl m-0'>
          Our Mission Statement
        </h2>
        <p className='leading-loose text-center text-sm sm:text-xl lg:text-3xl xl:text-4xl m-0 mt-4 md:mt-8 lg:mt-16'>
          Help businesses take advantage of technology to drive their business forward.
        </p>
      </div>
    </section>
  )
}

export default VisionSection
