import React from 'react'
import phoneIllustration from '../../assets/images/about-page/phone-illus.svg'

const AboutUsSection = () => {
  return (
    <section className='container text-center mt-8 flex items-center'>
      <img src={phoneIllustration} alt='An illustration of a person reading a phone' className='hidden h-80 lg:block w-1/3' />
      <div className='w-4/5 lg:w-2/3 mx-auto lg:mx-8 mt-4 lg:mt-0'>
        <h2 className='m-0 p-0 text-center'>About Us</h2>
        <p className='leading-loose lg:leading-9 lg:text-lg'>
          We Do Code demystify technology for business owners and key decision makers, who know they need to invest in technology to grow their business, but need the expertise and guidance to help them get there.
        </p>
        <p className='leading-loose lg:leading-9 mt-4 lg:text-lg'>
          We never submit a proposal that we do not genuinely believe offers good value for money and a clear path to a significant return on investment for our clients.
        </p>
      </div>
    </section>
  )
}

export default AboutUsSection
