import { graphql, useStaticQuery } from 'gatsby'

export const useTeamMembersList = () => {
  const { allContentfulTeamMember } = useStaticQuery(
    graphql`
      {
        allContentfulTeamMember(sort: {fields: displayOrder, order: ASC}) {
          nodes {
            blurb {
              blurb
            }
            cartoonImage {
              gatsbyImageData(width: 200)
              title
            }
            id
            jobTitle
            name
            personallyChosenTitle
            quote
            realImage {
              gatsbyImageData(width: 200)
              title
            }
          }
        }
      }
    `
  )
  return allContentfulTeamMember.nodes
}


